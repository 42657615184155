export default {
  "ATTENTION: without the unique return code, your package cannot be identified.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ВНИМАНИЕ: без уникалния код за връщане вашият пакет не може да бъде идентифициран."])},
  "Bank account number starts with %s (example: %s).": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номерът на банковата сметка започва с%s (пример: %s)."])},
  "Be sure to include your unique complaint number with the product in the package. Your unique complaint number is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не забравяйте да включите уникалния си номер на оплакване с продукта в опаковката. Вашият уникален номер на оплакване е"])},
  "Comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментар"])},
  "Damaged product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повреден продукт"])},
  "Greetings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поздрави"])},
  "Hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Здравейте!"])},
  "Information is not required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не се изисква информация"])},
  "No reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма причина"])},
  "Once your return has been received at our offices, an e-mail will be sent to the address entered in the complaint form where we will inform you about the latest updates on the return process.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["След като бъде получено Вашето връщане в нашите офиси, ще бъде изпратен имейл на адреса, въведен във формуляра за жалби, където ще Ви информираме за последните актуализации на процеса на връщане."])},
  "Order number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер на поръчката"])},
  "Paid on our website, and not with the delivery service / courier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Платени на нашия уебсайт, а не с услугата за доставка/куриер"])},
  "Product quality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Качество на продукта"])},
  "Product replacement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Замяна на продукта"])},
  "Product return procedure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Процедура за връщане"])},
  "Product return process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Процес на връщане на продукта"])},
  "Reason for the complaint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Причина за жалбата"])},
  "Refund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Възстановяване"])},
  "Refund form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Формуляр за възстановяване"])},
  "Returning the product is quick and easy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Връщането на продукта е бързо и лесно"])},
  "Returning the product is quick and easy.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Връщането на продукта е бързо и лесно"])},
  "STEP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стъпка"])},
  "Select type of complaint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете тип жалба"])},
  "Send the package to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изпратете пакета на"])},
  "Send the package to the following address:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изпратете пакета на следния адрес:"])},
  "Something went wrong, try again later or contact our support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нещо се обърка, опитайте отново по-късно или се свържете с нашата поддръжка"])},
  "Submit the form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изпратете формуляра"])},
  "The bank account number starts with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номерът на банковата сметка започва с %s (пример: %s)."])},
  "The return procedure has just been forwarded to your e-mail address:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Процедурата за връщане току-що беше препратена на вашия имейл адрес:"])},
  "Together with the product to be returned, enter your unique return code in the package.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заедно с продукта, който ще бъде върнат, въведете уникалния си код за връщане в пакета.\n"])},
  "Type of payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вид на плащане"])},
  "When we receive the package in our branch, you will be informed about the course of the complaint to your e-mail address, which you provided when submitting the complaint form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Когато получим пакета в нашия клон, ще бъдете информирани за хода на жалбата на Вашия имейл адрес, който сте предоставили при подаване на формуляра за жалба"])},
  "Wrong product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Грешен продукт"])},
  "Wrong quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Грешно количество"])},
  "You can print out already completed information to help return the product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Можете да отпечатате вече попълнена информация, за да помогнете за връщането на продукта"])},
  "You have already submitted a return request. Be patient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вече сте подали заявка за връщане. Бъдете търпеливи"])},
  "You have received a PDF file for return procedure in the attachment.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получихте PDF файл за процедура за връщане в прикачения файл."])},
  "Your unique return number is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вашият уникален номер за връщане е"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Град"])},
  "complaint number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["номер на жалбата"])},
  "example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пример"])},
  "first name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Собствено име"])},
  "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Инструкции"])},
  "last name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фамилно име"])},
  "post code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пощенски код"])},
  "reciever": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["рецивър"])},
  "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подател"])}
}